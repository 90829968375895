.Header {
  position: sticky;
  z-index: 9;
  box-shadow: 0 0 13px #53423c08;
  top: 0;
  padding: clamp(16px, calc(32vw / 7.68), 32px) 0 12px;
  background-color: var(--primary-100);
  a > svg {
    max-width: 52vw;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      gap: clamp(16px, calc(24vw / 7.68), 40px);
      a {
        color: var(--primary-600);
        transition: color 0.4s;
        &:hover {
          color: var(--primary-800);
        }
        display: block;
      }
    }
  }
}